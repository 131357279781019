<template>
	<div id="page" :style="bg ? `background-image: url('${bg}');` : ''">
		<div class="content" >
			<van-row style="height:100%">
				<van-col offset="2" span="20" style="height:100%">
					<div class="content-label">
						<div class="rankingList">
							<div class="overflow">
								<template v-for="(item,index) in rankingList" :key="item" :index="index">
										<van-row >
											<van-col offset="1" span="22">
												<div class="rankingbg" :class="index<3 ?'head-rankingbg' :''" >
													<transition name="bounce">
													<div v-show="isShow(index)">
														<div class="progress" :class="index<3 ?'head-progress' :''" :style="`width:${item.percent}%`">
														</div>
														<div class="rankingItem" :class="index<3 ?'head-rankingItem' :''">
															<van-row>
																<van-col span="1" >
																	<template v-if="index<3">
																		<img class="crown" src="../assets/crown.png" />
																	</template>
																	<template v-else>
																		<div class="center rankingItemStr youshe blue weight">{{index+1}}</div>
																	</template>
																</van-col>
																<van-col span="18">
																	<div class="rankingItemStr youshe blue">{{item.cn_title}} / {{item.en_title}}</div>
																</van-col>
																<van-col span="5">
																	<div class="right rankingItemStr count youshe weight"  :class="index<3 ?'head-count' :''">{{item.count}}</div>
																</van-col>
															</van-row>
														</div>
													</div>
													</transition>
												</div>
											</van-col>
										</van-row>
								</template>
							</div>
						</div>
					</div>
				</van-col>
			</van-row>
		</div>
		<template v-if="menuItems">
		<toggle-menu
			position="RB"
			:menuItems="menuItems"
            @clickMenu="clickMenu"
			menuBg="#2A3E53"
			itemBg="#2A3E53"
        />
		</template>
	</div>
</template>

<script>
import { reactive, toRefs , onMounted ,onUnmounted} from 'vue';
import { useRouter } from 'vue-router';
import toggleMenu from '../components/toggleMenu';
import { get } from '../library/serviceUtil';
import Common from '../library/Common';
import Menu from '../library/Menu';
import '../css/font.css';

export default {
	name: 'ranking',
	components: {
		toggleMenu
	},
    setup(){
		const router = useRouter();
		const common = new Common();
		const menu = new Menu("ranking",router,common);
		const activity = common.getLocal("activity");
		let runInterval = null;
		let showInterval = null;
		let showTimeout = null;
		let state = reactive({
			menuItems:null,
			bg:null,
			bg_music:null,
			rankingList:[],
			showRow:[],
		});
		const format = function(){
			if(state.rankingList.length>0){
				var max = state.rankingList[0].count *1.5;
				for(let ranking of state.rankingList){
					ranking.percent = parseInt(ranking.count/max*100);
				}
			}
		}

		const isShow = function(index){
			return state.showRow.indexOf(index) > -1;
		}

		const clickMenu = function(item){
			menu.click(item);
		}

		const showList = ()=>{
			let length = 0;
			showTimeout = setTimeout(()=>{
				showInterval = setInterval(()=>{
					state.showRow.push(length);
					length++;
					if(length == state.rankingList.length){
						clearInterval(showInterval);
					}
				},300);
			},300);
		}

		const getVoteList = async()=>{
			var res = await get(`activity/program/${activity.voting.id}/`);
			if(res.status == 200 && res.data.voting){
				var list = res.data.voting.map((item)=>{
					return {
						cn_title:item.cn_title,
						en_title:item.en_title,
						count:item.count,
					}
				});
				state.rankingList = list.sort((a,b)=>{
					return b.count -a.count;
				})
				return true;
			}
			return false;
		}

		const getProgram = async()=>{
			var res = await get(`/activity/program/${activity.voting.id}/`);
			if(res.status == 200){
				state.bg = res.data.banner;
				state.bg_music = res.data.bg_music;
				menu.setMusic(res.data.bg_music);
			}
		}

		const init = async()=>{
			if(await getVoteList()){
				format();
				showList();
				runInterval = setInterval(async () => {
					await getVoteList();
					format();
				}, 3000);
			}
		}

		onMounted(async()=>{
			await menu.init();
			state.menuItems = menu.menuItems;
			await getProgram();
			await init();
		});
		onUnmounted(()=>{
			clearInterval(runInterval);
			clearInterval(showInterval);
			clearTimeout(showTimeout);
		});
		return {
			...toRefs(state),
			isShow,
			clickMenu,
		};
	}
}
</script>
<style scoped>
.bounce-enter-active {
	animation: flipInX 1s;
}
.bounce-leave-active {
	animation: flipInX 1s reverse;
}

#page{
	width: 100%;
	height: 100%;
	background-color: #fff;
	background-image: url('../assets/bg.png');
	background-repeat: no-repeat;
    background-size: 100% 100%;
	cursor:default;
}
.content{
	position:absolute;
	top:15%;
	height:70%;
	width:100%;
	margin:auto;
}

.content-label{
	width:100%;
	height:100%;
	background-image: url('../assets/ranking_list_bg.png');
	background-repeat: no-repeat;
    background-size: 100% 100%;
}

.rankingList{
	padding-top:6%;
	height:82%;
}

.overflow{
	height:100%;
	width: 99%;
	overflow-y: auto;
	overflow-x: hidden;
}

.overflow::-webkit-scrollbar {/*滚动条整体样式*/
	width: 0.1rem;     /*高宽分别对应横竖滚动条的尺寸*/
	height: 1px;
}

.overflow::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: #D3AC85;
}

.overflow::-webkit-scrollbar-track {/*滚动条里面轨道*/
    box-shadow: inset 0 0 0.1rem rgba(0,0,0,0.2);
	border-radius: 10px;
	background: rgba(5,5,5,0.2);
}

.crown{
	position:relative;
	width: 0.3rem;
	top:-0.1rem;
	left:-0.1rem;
}
.rankingbg{
	border-radius: 2px;
	background-color:rgba(0,0,0,0.5);
	color:#000;
	width: 100%	;
	margin-top: 0.1rem;
	height: 0.20rem;
	line-height: 0.20rem;
}
.head-rankingbg{
	height: 0.25rem;
	line-height: 0.25rem;
}
.progress{
	position:relative;
	border-radius: 2px;
	width: 50%;
	background-color: #fff;
	height: 0.20rem;
	line-height: 0.20rem;
	background-image: linear-gradient(#A9CDF2, #0099FF);
}
.head-progress{
	height: 0.25rem;
	line-height: 0.25rem;
	background-image: linear-gradient(#FCE2BB, #C49C73);
}
.rankingItemStr{
	color:#fff;
}
.rankingItem{
	top:-0.2rem;
	position:relative;
	z-index:2;
}
.head-rankingItem{
	top:-0.25rem;
}

.count{
	padding-right:0.1rem;
	color:#6D9FC4;
	
}
.weight{
	font-weight:600;
}

.blue{
	color:#203141;
}
.youshe{
	font-size:0.15rem;
	font-family: 'youshe';

}
.head-count{
	color:#DBC8AF;
}
</style>